import { useState, useContext } from "react";
import './CartItem.css';
import { CiImageOn } from "react-icons/ci";
import { CartContext } from '../../context/CartContext';

export default function CartItem(props) {
    const { image, albumName, size, price, id, pages, coverImage, coverType, coverName, albumOptions } = props;
    const [quantity, setQuantity] = useState(props.quantity || 1);
    const [imageError, setImageError] = useState(false);
    const { updateQuantity, removeFromCart, addToCart } = useContext(CartContext);

    // Update quantity in the CartContext
    const handleQuantityChange = (e) => {
        const newQuantity = parseInt(e.target.value);
        setQuantity(newQuantity);
        updateQuantity(id, newQuantity); // Use id to update the quantity for the specific item
    };

    // Remove item based on id and options
    const handleRemoveItem = () => {
        removeFromCart(albumOptions, size, coverType, coverName);  // Pass all necessary data to remove the correct item
    };

    // Add duplicate item to cart with the original image and cover info
    const handleAddDuplicate = () => {
        if (albumOptions?.dupPriceId) {
            const duplicateItem = {
                ...props,
                id: `${id}-duplicate`, // Use id for the unique identifier
                isDuplicate: true, // Mark as a duplicate
                price: (price * 0.7).toFixed(2), // Apply a 30% discount
                albumOptions: { ...albumOptions }, // Ensure the album options are duplicated correctly
                coverSelection: {
                    coverType,
                    coverName,
                    url: coverImage,
                },
            };
            addToCart(duplicateItem);
        }
    };

    return (
        <div className="cartItemContainer">
            <div className="imageArea">
                {imageError ? (
                    <CiImageOn size={80} color="#ccc" />
                ) : (
                    <img
                        src={image}
                        alt="Product"
                        onError={() => setImageError(true)}
                    />
                )}
            </div>

            <div className="itemName">
                <h4>{albumName || 'Product Name'}</h4>
                <h6>Size: {size || 'N/A'}</h6>
                <h6>Pages: {pages || 'N/A'}</h6>
                <h6>Cover: {coverType || 'N/A'} - {coverName || 'N/A'}</h6>

                {coverImage ? (
                    <img 
                        src={coverImage}
                        alt="Cover Selection"
                        style={{ width: '50px', borderRadius: '15px' }} 
                    />
                ) : (
                    <CiImageOn size={80} color="#ccc" />
                )}
            </div>

            <div className="priceArea">
                <div className="quantPrice">
                    <input
                        type="number"
                        name="quantity"
                        min={1}
                        value={quantity}
                        onChange={handleQuantityChange}
                    />
                    <h5>${(price * quantity).toFixed(2)}</h5>
                </div>

                <div className="saveRemove">
                    <p onClick={handleRemoveItem} style={{ cursor: 'pointer', color: 'red' }}>Remove</p>
                </div>

                {albumOptions?.dupPriceId && !props.isDuplicate && (
                    <div className="duplicateOption">
                        <button onClick={handleAddDuplicate} className="duplicateButton">
                            Add Duplicate Album for 30% OFF!
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
