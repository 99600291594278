import { Link } from "react-router-dom"
import ProductCard from '../../components/cards/productCard'
import FullPageCard from '../../components/cards/fullPageCard'
import Navbar from "../../components/navbars/Navbar"

// IMPORT IMAGES ************************
import Album1 from '../../assets/images/Lux-Specialty-RusticBrass.jpg'
import PressBook from '../../assets/images/Press/Press-Book.jpg'
import Economy from '../../assets/images/Economy/EconomyAlbum_005.jpg'
import Fabric from '../../assets/images/Fabric/Fabric.jpg'
import GalleryFaux from '../../assets/images/Gallery/Gallery-Ivory.jpg'

// MAIN *************************************************
export default function AlbumsPage() {

    return(
        <div>
            <Navbar/>
            <h6 style={{backgroundColor: '#3c3c3c', color: 'white', padding: '7px'}}>All of our albums start with 30 pages and basic album design is included</h6>
            <FullPageCard 
                image={Album1}
                title='The Lux (Our Premium Albums)'
                price='Starting at $899'  
                          
            />
            <FullPageCard 
                image={GalleryFaux}
                title='Gallery Albums'
                price='$999'  
                              
            />
            <FullPageCard 
                image={Economy}
                title='Economy Albums'
                price='$699'  
                          
            />
            <FullPageCard 
                image={PressBook}
                title='Press-Book'
                price='$249'  
                          
            />
           
        </div>
    )
}