import React, { useState, useContext } from 'react';
import './addAlbumsPage.css';
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../context/UserProvider';
import axios from 'axios';

export default function AddAlbumsPage() {
  const navigate = useNavigate()
  const { user} = useContext(UserContext)
  const [albumName, setAlbumName] = useState('');
  const [albumOptions, setAlbumOptions] = useState([]);
  const [pages, setPages] = useState(20);
  const [size, setSize] = useState('6x9');
  const [price, setPrice] = useState('');
  const [cost, setCost] = useState('');
  const [designCost, setDesignCost] = useState('');
  const [images, setImages] = useState([]);
  const [coverOptions, setCoverOptions] = useState([])
  const [coverType, setCoverType] = useState('')
  const [coverName, setCoverName] = useState('')
  const category = "Albums";

  function handlePageChange(e, index) {
    const newOptions = [...albumOptions];
    newOptions[index].pages = e.target.value;
    setAlbumOptions(newOptions);
  }

  function handleSizeChange(e, index) {
    const newOptions = [...albumOptions];
    newOptions[index].size = e.target.value;
    setAlbumOptions(newOptions);
  }

  function handleCostChange(e, index) {
    const newOptions = [...albumOptions];
    newOptions[index].cost = e.target.value;
    setAlbumOptions(newOptions);
  }

  function handleDesignCostChange(e, index) {
    const newOptions = [...albumOptions];
    newOptions[index].designCost = e.target.value;
    setAlbumOptions(newOptions);
  }

  function handlePriceChange(e, index) {
    const newOptions = [...albumOptions];
    newOptions[index].price = e.target.value;
    setAlbumOptions(newOptions);
  }
  
  function handleCoverOptionType(e, index){
    const newCoverOptions = [...coverOptions]
    newOptions[index].coverType = e.target.value
    setAlbumOptions(newOptions)
  }
  function handleCoverOptionName(e, index){
    const newCoverOptions = [...coverOptions]
    newOptions[index].coverName = e.target.value
    setAlbumOptions(newCoverOptions)
  }

  function handleImages(e) {
    setImages(e.target.files);
  }

  function handleAddOption() {
    const newOption = {
      pages,
      size,
      price,
      cost,
      designCost,
    };
    function handleAddCoverOptions(){
      const newCoverOption = {
        coverType,
        coverName
      }
    }
    
    setAlbumOptions((prevOptions) => [...prevOptions, newOption]);
    // Clear input fields after adding the option
    setPages(20);
    setSize('6x9');
    setPrice('');
    setCost('');
    setDesignCost('');
  }
    setCoverOptions((prevOptions) => [...prevOptions, newCoverOption])

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('category', category);
    formData.append('albumName', albumName);
    formData.append('albumOptions', JSON.stringify(albumOptions));

    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }

    try {
      await axios.post(`${import.meta.env.BASE_API}/albums/add-albums`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      // Reset form after successful submission
      setAlbumOptions([]);
      setImages([]);
      setAlbumName('');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }
  useEffect(() => {
    if(!user){
      navigate('/login')
      return
    }
  },[user, navigate])

  return (
    <main>
      <h1>Add Album Page</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Album Name"
          value={albumName}
          onChange={(e) => setAlbumName(e.target.value)}
          tabIndex={1}
        />
        {albumOptions.map((option, index) => (
          <div className="albumOptions" key={index}>
            <select value={option.pages} onChange={(e) => handlePageChange(e, index)} style={{ width: '150px' }}>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </select>
            <select value={option.size} onChange={(e) => handleSizeChange(e, index)} style={{ width: '150px' }}>
              <option value="6x9">6x9</option>
              <option value="8x8">8x8</option>
              <option value="10x10">10x10</option>
              <option value="12x12">12x12</option>
              <option value="12x18">12x18</option>
            </select>
            <input
              value={option.cost}
              placeholder="Album Cost"
              onChange={(e) => handleCostChange(e, index)}
            />
            <input
              value={option.designCost}
              placeholder="Design Cost"
              onChange={(e) => handleDesignCostChange(e, index)}
            />
            <input
              value={option.price}
              placeholder="Album Price"
              onChange={(e) => handlePriceChange(e, index)}
            />
            
          </div>
        ))}
        <button type="button" onClick={handleAddOption}>
          Add Options
        </button>
        <input type="file" multiple onChange={handleImages} />
        <div>
          {coverOptions.map((option, index) => {
           <div className="albumOptions" key={index}>
            <input
            value={option.coverType}
            placeholder="Cover Type"
            onChange={(e) => handleCoverOptionType(e, index)}
            />
          <input
            value={option.coverName}
            placeholder="Cover Name"
            onChange={(e) => handleCoverOptionName(e, index)}
            />
           </div>
          })}
        <button type="button" onClick={handleAddCoverOption}>
          Add Cover Options
        </button>
        </div>
        <button type="submit">Submit</button>
      </form>
    </main>
  );
}
