import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './albumsOrder.css'
import ReactLoading from 'react-loading'
import Navbar from "../../components/navbars/Navbar";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { GoDot, GoDotFill } from "react-icons/go";
import axios from 'axios'




// IMAGES *************************
import Lux from '../../assets/images/Lux-Specialty-RusticBrass.jpg'
import Gallery from '../../assets/images/Gallery/Gallery-Ivory.jpg'
import Economy from '../../assets/images/Economy/EconomyAlbum_005.jpg'
import PressBook from '../../assets/images/Press/Press-Book-Corner.jpeg'
import Talon from '../../assets/images/talon.jpg'
import USFlag from '../../assets/images/USFlag.jpg'
import DoubleCorePages from '../../assets/images/product/pages/Core.jpg'



export default function AlbumsOrder() {
    const [data, setData]= useState([])
    const [loading, setLoading] = useState(false)
    const [imageIndex, setImageIndex] = useState(0);
    const [imageIndex2, setImageIndex2] = useState(0);
    const [imageIndex3, setImageIndex3] = useState(0);
    const [imageIndex4, setImageIndex4] = useState(0);
    
    const images = [Lux, DoubleCorePages]
    const images2 =[Gallery, DoubleCorePages]
    const images3 =[Economy, DoubleCorePages]
    const images4 =[PressBook]


// FUNCTIONS ****************************************
function handleForwardPhoto() {
    setImageIndex(index => (index === images.length - 1 ? 0 : index + 1));
}

function handleRearPhoto() {
    setImageIndex(index => (index === 0 ? images.length - 1 : index - 1));
}
function handleForwardPhoto2() {
    setImageIndex2(index2 => (index2 === images2.length - 1 ? 0 : index2 + 1));
}

function handleRearPhoto2() {
    setImageIndex2(index2 => (index2 === 0 ? images2.length - 1 : index2 - 1));
}
function handleForwardPhoto3() {
    setImageIndex3(index3 => (index3 === images3.length - 1 ? 0 : index3 + 1));
}

function handleRearPhoto3() {
    setImageIndex3(index3 => (index3 === 0 ? images3.length - 1 : index3 - 1));
}
function handleForwardPhoto4() {
    setImageIndex4(index4 => (index4 === images4.length - 1 ? 0 : index4 + 1));
}

function handleRearPhoto4() {
    setImageIndex3(index4 => (index4 === 0 ? images4.length - 1 : index4 - 1));
}

// useEffect ***********************************************


    return(
        <main>
            <Navbar/>
            <h1>Select Your Album Type</h1>
            <h3>(Initial Album Design Included)</h3>
            <div className="albumSelection">
                <Link to={'/albums/press-book'}>
                <div className="albumType" 
                    style={{backgroundImage: `url(${PressBook})`}}
                    >
                    <div className='title'>
                        <h2>Press Book</h2>
                        <h4>Starting at $299</h4>
                    </div>
                
                </div>
                </Link>
                <Link to={'/albums/economy'}>
                <div className="albumType" 
                    style={{backgroundImage: `url(${Economy})`}}
                    >
                    <div className='title'>
                        <h2>Economy</h2>
                        <h4>Starting at $449</h4>
                    </div>
                
                </div>
                </Link>
                
                <Link to={'/albums/Gallery'}>

                <div className="albumType"
                    style={{backgroundImage: `url(${Gallery})`}}
                    >
                    <div className='title'>
                        <h2>Gallery</h2>
                        <h4>Starting at $699</h4>
                    </div>
                </div>
                </Link>
                <Link to={'/albums/Lux'}>


                <div className="albumType"
                    style={{backgroundImage: `url(${Lux})`}}
                    >
                    <div className='title'>
                        <h2>Lux</h2>
                        <h4>Starting at $899</h4>
                    </div>
                </div>
                </Link>
                
            </div>
{/*  **LUX**********LUX*******LUX*********LUX************LUX*****LUX************************   */}
            <div className='aboveFold' style={{backgroundColor: '#3c3c3c', border: '0px', borderRadius: '15px', width: '99%', margin: '10px auto'}}>
               
               <div className='itemImage'>
                   <FaChevronCircleLeft color='#967f71' id='leftArrow' size={25} onClick={handleRearPhoto} />

                   <img
                       src={images[imageIndex]}
                       alt="Album"
                       style={{borderRadius: '15px'}}
                   />

                   <FaChevronCircleRight color='#967f71' id='rightArrow' size={25} onClick={handleForwardPhoto} />

                   <div className='imageActiveCircle'>
                       {images.map((item, key) => (
                           <div style={{ display: 'flex' }} key={key}>
                               {imageIndex === key ? (
                                   <GoDotFill size={20} color='#967f71' />
                               ) : (
                                   <GoDot size={20} color='#967f71' />
                               )}
                           </div>
                           ))
                       }
                   </div>
               </div>
               <div className="talkingPoint">
                   <h2 style={{color: 'white'}}>Lux</h2>
                   <h4 style={{color: 'white'}}>Our premier hand-crafted album with premium leather, pleated corners and double core pages.  Truly an heirloom to keep around</h4>
                   <Link to={'/albums/lux'}>
                       <button>Select</button>
                   </Link>
               </div>
              
           </div>
{/*  **GALLERY**********GALLERY***********GALLERY************GALLERY*****GALLERY********************   */}
            <div className='aboveFold' style={{ border: '0px', borderRadius: '15px', width: '99%', margin: '10px auto'}}>
            <div className="talkingPoint">
                   <h2 style={{color: '#3c3c3c'}}>Gallery</h2>
                   <h4 style={{color: '#3c3c3c'}}>Available with faux leather or fabric cover, up to 30 pages with a variety of covers to chose from</h4>
                   <Link to={'/albums/gallery'}>
                       <button>Select</button>
                   </Link>
               </div>
               <div className='itemImage'>
                   <FaChevronCircleLeft color='#967f71' id='leftArrow' size={25} onClick={handleRearPhoto2} />

                   <img
                       src={images2[imageIndex2]}
                       alt="Gallery Album"
                       style={{borderRadius: '15px'}}
                   />

                   <FaChevronCircleRight color='#967f71' id='rightArrow' size={25} onClick={handleForwardPhoto2} />

                   <div className='imageActiveCircle'>
                       {images2.map((item, key) => (
                           <div style={{ display: 'flex' }} key={key}>
                               {imageIndex2 === key ? (
                                   <GoDotFill size={20} color='#967f71' />
                               ) : (
                                   <GoDot size={20} color='#967f71' />
                               )}
                           </div>
                           ))
                       }
                   </div>
               </div>
              
              
           </div>
{/*  ***ECONOMY*************************************ECONOMY*****************ECONOMY************************   */}
            <div className='aboveFold' style={{backgroundColor: '#3c3c3c', border: '0px', borderRadius: '15px', width: '99%', margin: '10px auto'}}>
               
               <div className='itemImage'>
                   <FaChevronCircleLeft color='#967f71' id='leftArrow' size={25} onClick={handleRearPhoto3} />

                   <img
                       src={images3[imageIndex3]}
                       alt="Album"
                       style={{borderRadius: '15px'}}
                   />

                   <FaChevronCircleRight color='#967f71' id='rightArrow' size={25} onClick={handleForwardPhoto3} />

                   <div className='imageActiveCircle'>
                       {images3.map((item, key) => (
                           <div style={{ display: 'flex' }} key={key}>
                               {imageIndex3 === key ? (
                                   <GoDotFill size={20} color='#967f71' />
                               ) : (
                                   <GoDot size={20} color='#967f71' />
                               )}
                           </div>
                           ))
                       }
                   </div>
               </div>
               <div className="talkingPoint">
                   <h2 style={{color: 'white'}}>Economy Albums</h2>
                   <h4 style={{color: 'white'}}>A quick quality album that comes black with 20 pages in 8x8 or 10x10</h4>
                   <Link to={'/albums/economy'}>
                       <button>Select</button>
                   </Link>
               </div>
              
           </div>
{/*  **PRES BOOK********PRES BOOK******PRESS BOOK*****PRESS BOOK******PRESS BOOK******************   */}
            <div className='aboveFold' style={{ border: '0px', borderRadius: '15px', width: '99%', margin: '10px auto'}}>
            <div className="talkingPoint">
                   <h2 style={{color: '#3c3c3c'}}>Press Book</h2>
                   <h4 style={{color: '#3c3c3c'}}>Our photo cover album with magazine style pages. Available in 6x9, 8x8, or 10x10 up to 40 pages  </h4>
                   <Link to={'/albums/press-book'}>
                       <button>Select</button>
                   </Link>
               </div>
               <div className='itemImage'>
                   <FaChevronCircleLeft color='#967f71' id='leftArrow' size={25} onClick={handleRearPhoto4} />

                   <img
                       src={images4[imageIndex4]}
                       alt="Album"
                       style={{borderRadius: '15px'}}
                   />

                   <FaChevronCircleRight color='#967f71' id='rightArrow' size={25} onClick={handleForwardPhoto4} />

                   <div className='imageActiveCircle'>
                       {images.map((item, key) => (
                           <div style={{ display: 'flex' }} key={key}>
                               {imageIndex === key ? (
                                   <GoDotFill size={20} color='#967f71' />
                               ) : (
                                   <GoDot size={20} color='#967f71' />
                               )}
                           </div>
                           ))
                       }
                   </div>
               </div>
              
              
           </div>
        </main>
    )
}






   
