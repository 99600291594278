import Navbar from '../../components/navbars/Navbar'
import './about.css'
import kirkSurberHeadshot from '../../assets/images/KSheadshot.jpg'
import { MdTimelapse } from "react-icons/md";
import { FaLayerGroup } from "react-icons/fa";
import { BsLightningChargeFill } from "react-icons/bs";


export const metadata = {
    title: 'Photo Studio App',
    keywords: 'Photo Studio App, About Page',
    description: 'Photo Studio App is a Client Management tool or CRM for photographers to navigate the business of photography without the need for multiple costs just for tools you should have in 1 application'
}

export default function About () {

   
    return (
        <main>
            <Navbar/>
            <div className='aboveTheFold'>
                <h1>About Albums and Art</h1>
                <p>We make album creation easy</p> 
                
                <div className='process'>
                    <div className='featureSection'>
                        <MdTimelapse size={30} className='iconImage'/>
                        <h5>Select Your Album or Wall Art</h5>
                    </div>
                    <div className='featureSection'>
                        <FaLayerGroup className='iconImage' size={30}/>
                        <h5>Upload Your Image/s</h5>
                    </div>
                    <div className='featureSection'>
                        <BsLightningChargeFill className='iconImage'  size={30}/>
                        <h5>We edit, build and send</h5>
                        
                    </div>
                </div>

                <div className='whoWeAre'>
                    <p className='whoWeAreTitle'>We Understand Your Photos are Important</p>
                    <div className='team'>
                        <div className='teamPhoto'>
                            <img></img>
                            <h4>We're currently building our team and will be looking for customer service reps soon!</h4>
                        </div>
                        <div className='teamDescription'>
                            <p>As a photographer myself, I understand there are many of you with photos sitting around on a hard-drive with no idea what to do with them.  </p>
                            <br/>
                            <p>This is why we created Albums and Art to handle the design, editing and offer any help you may need for getting your photos into an album or up on your wall with a large print. </p>
                        </div>
                    </div>
                </div>
                <div className='missionArea'>
                    <div className='mission'>
                        <p className='whoWeAreTitle'>Mission</p>
                        
                        <div className='missionDescription'>
                            <p>Make an app for photographers who run sessions, sales, and need time to market their business, not worry about what widget to use.</p>
                            <br/>
                            <p><strong>Simple, easy to use, intuitive</strong></p>
                        </div>
                    </div>
                    <div className='mission'>
                        <p className='whoWeAreTitle'>Goals</p>
                        
                        <div className='missionDescription'>
                            <p>Our mission is to make an app for photographers who run sessions, sales, and need time to market their business, not worry about what widget to use.</p>
                            <br/>
                            <p><strong>Simple, easy to use, intuitive</strong></p>
                        </div>
                    </div>
                    <div className='mission'>
                        <p className='whoWeAreTitle'>Education</p>
                        
                        <div className='missionDescription'>
                            <p>The Veteran in me is a trainer at heart so expect to have educational videos, conferences and meetups along the way.  From shooting to running ad campaigns, it's coming. </p>
                            <br/>
                            <p><strong>Never stop learning</strong></p>
                        </div>
                    </div>
                </div>
                <div className='ownerArea'>
                    <p className='whoWeAreTitle'>Veteran Owned, Dog Lover, Dad</p>
                    <div className='owner'>
                        <div className='ownerPhoto'>
                            <img alt='Owner Photo' src={kirkSurberHeadshot}></img>
                        </div>
                        <div className='ownerDescription'>
                            <p>I have built this app to help me with my own photography business.  I got tired of using applications to run my business.  CRM's or Client Management tools were a pain and often so generalized I needed something for photographers.  So Photo Studio App was born. </p>
                            
                            
                        </div>
                    </div>
                </div>
                    
            </div>
        </main>
    )
}

