import { useRouteError, Link } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <main>
      <div id="error-page"  
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#242424', 
          justifyContent: 'center', 
          alignItems: 'center',
          width: '500px',
          height: '300px',
          borderRadius: '15px',
          margin: '100px auto'
            }}
          >
        <h1 style={{color: 'royalblue'}}>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
          
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
        <Link to="/">
          <button className="button1">Go to Home page</button>
        </Link>
      </div>
    </main>
  );
}