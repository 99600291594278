import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
import './orderTrackingPage.css'
import AdminBar from "../../../components/navbars/adminBar";
import ClientOrder from "../../../components/cards/clientOrderCard/ClientOrder";
import Navbar from "../../../components/navbars/Navbar";
import axios from 'axios'




export default function OrdersPage(){
const navigate =useNavigate()
    const {user} = useContext(UserContext)
    const [orderNumber, setOrderNumber]=useState('')
    const [productValue, setProductValue]=useState('')
    const [discount, setDiscount]=useState('0')
    const [tax, setTax]=useState('')
    const [totalValue, setTotalValue]=useState('')
    const [firstName, setFirstName]=useState('')
    const [lastName, setLastName]=useState('')
    const [date, setDate] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        if(!user){
            navigate('/login')
            return
        }
        setLoading(true)
        const getData = async () => {
         try{
            const response = await axios.get(`${import.meta.env.BASE_API}/orders/api/active-orders`)
            if(response.data.length >= 0 && response){
                setData(response.data)
                setLoading(false)
            }
            else {
                console.log("No orders found")
            }
         }
         catch(err) {
            console.log(err)
         }
         finally{
            setLoading(false)
         }
        }
        getData()
        
    },[user])

    useEffect(() => {
        if(data){
            console.log("data:", data)
        }
    },[data])

    const getBorderColor = (statusHistory) => {
        if(!statusHistory || statusHistory.length === 0){
            return 'gray'
        }
        const lastStatus = statusHistory[statusHistory.length - 1];
        console.log("last Status:", lastStatus)
        const lastUpdateDate = new Date(lastStatus.updatedAt);
        
        console.log("lastUpdateDate:", lastUpdateDate)
        const now = new Date();
        const diffDays = Math.ceil((now - lastUpdateDate) / (1000 * 60 * 60 * 24));

        if (lastStatus.status === 'Completed') {
            return 'black'; // Order completed
        } else if (diffDays > 3) {
            return 'red'; // 3 days after last update
        } else if (lastStatus.status === 'Send to manufacturing') {
            return '#ffa500'; // Send to manufacturing
        } else if (lastStatus.status === 'Manufacturing') {
            return '#ffa800'; //manufacturing
        } else if (lastStatus.status === 'Sent to editing team') {
            return 'purple'; // Sent to editing team
        } else if (lastStatus.status === 'Awaiting user images'){
            return '#ff4500'
        } 
        
        else {
            return 'gray'; // Default for other statuses
        }
    };

    return(
        <main>
            <Navbar/>
            <h1>Outstanding Orders Page</h1>
            <div className="colorDefsContainer">
                <div className="colorDefs" style={{backgroundColor: '#FFA500'}}>Send to Manufacturing</div>
                <div className="colorDefs"  style={{backgroundColor: '#ffa800'}}>Manufacturing</div>
                <div className="colorDefs"  style={{backgroundColor: 'purple'}}>Sent to Editing</div>
                <div className="colorDefs"  style={{backgroundColor: '#ff4500'}}>Awaiting User Images</div>
                <div className="colorDefs"  style={{backgroundColor: 'red'}}>3 Day Warning</div>
                <div className="colorDefs"  style={{backgroundColor: 'black'}}>Completed</div>
            </div>
            <div className="outstandingOrders">
                { data.length > 0 &&
                    data.map((order, index) => {
                        const borderColor = getBorderColor(order.statusHistory)
                        return(

                            <ClientOrder
                                key={order._id}
                                orderId={order._id}
                                orderDate={order.createdAt}
                                preTaxPrice={order.cartPreTaxPrice}
                                tax={order.tax}
                                totalPrice={order.totalPrice}
                                orderNumber={order._id}
                                discount={discount}
                                firstName={order.firstName}
                                lastName={order.lastName}
                                data={order}
                                statusHistory={order.statusHistory}
                                border={borderColor}
                            />
                            
                        )
                    })
                }
                
            </div>
        </main>
    )
}