import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext, useRef } from 'react';
import { CartContext } from '../../context/CartContext';
import ReactLoading from 'react-loading';
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { GoDot, GoDotFill } from "react-icons/go";
import './productPage.css';
import axios from 'axios';

// Components
import EmptyNav from '../../components/navbars/EmptyNav';

export default function ProductPage() {
    const { addToCart } = useContext(CartContext);
    const { productName } = useParams();
    const navigate = useNavigate();
    const isFirstRender = useRef(true);

    // State
    const [imageIndex, setImageIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [coverImage, setCoverImage] = useState(null);  // Selected cover image (user-chosen)
    const [price, setPrice] = useState('');
    const [data, setData] = useState(null);
    const [sizeSelection, setSizeSelection] = useState(null);
    const [sizeAvailable, setSizeAvailable] = useState([]);
    const [coverTypes, setCoverTypes] = useState([]);
    const [coverSelection, setCoverSelection] = useState(null);  // Selected cover type

    // Image navigation
    const handleForwardPhoto = () => {
        setImageIndex(index => (index === images.length - 1 ? 0 : index + 1));
    };

    const handleRearPhoto = () => {
        setImageIndex(index => (index === 0 ? images.length - 1 : index - 1));
    };

    // Handle Size
    const handleSize = (e) => {
        const selectedSize = e.target.id;
        setSizeSelection(selectedSize);

        // Update price when size is selected
        const selectedProductOption = data?.productOptions?.find(option => option.size === selectedSize);
        if (selectedProductOption) {
            setPrice(selectedProductOption.price);
        }
    };

    // Handle Cover Type Selection (also sets the default cover for the selected type)
    const handleCoverType = (coverType) => {
        const filteredCovers = data?.coverOptions?.filter(option => option.coverType === coverType) || [];

        if (filteredCovers.length > 0) {
            const defaultCoverForType = filteredCovers[0];
            setCoverSelection({
                coverType: defaultCoverForType.coverType,
                coverName: defaultCoverForType.coverName,
                url: defaultCoverForType.url,
            });
            setCoverImage(defaultCoverForType.url);
        }
    };

    // Handle Cover Image Selection (updates both cover image URL and coverName)
    const handleCoverImage = (url, coverName) => {
        const selectedCover = data?.coverOptions?.find(option =>
            option.coverName === coverName && option.coverType === coverSelection?.coverType
        );

        if (selectedCover) {
            setCoverSelection({
                ...coverSelection,
                coverName: selectedCover.coverName,
                url: url,
            });
            setCoverImage(url);
        } else {
            console.error('Cover not found for coverName:', coverName);
        }
    };

    // Add to Cart
    const handleAddToCart = () => {
        const selectedCover = coverSelection;

        if (data && sizeSelection && selectedCover && coverImage) {
            const selectedProductOption = data?.productOptions?.find(option => option.size === sizeSelection);

            if (!selectedProductOption) {
                alert('Selected product option is not available.');
                return;
            }

            const cartItem = {
                id: data._id,
                productName: data.productName,
                category: data.category,
                imageUrl: images[0]?.url || '',  // Ensure the image exists
                price: selectedProductOption.price,
                size: sizeSelection,
                productOptions: {
                    size: selectedProductOption.size,
                    price: selectedProductOption.price,
                    optionId: selectedProductOption._id,
                    priceId: selectedProductOption.priceId,
                },
                coverSelection: {
                    url: coverImage,
                    coverType: selectedCover.coverType,
                    coverName: selectedCover.coverName,
                },
                quantity: 1,  // Default quantity
            };

            // Add the selected cart item to the cart
            addToCart(cartItem);
            navigate('/cart');
        } else {
            alert('Please select all required options before adding to the cart.');
        }
    };

    // Fetch Product Data
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(`${import.meta.env.VITE_API}/products/${productName}`);
                setData(response.data);
                setImages(response.data.images || []);  // Ensure images is an array
                
                // Safeguard for coverOptions - Check if it exists and is an array
                if (Array.isArray(response.data.coverOptions)) {
                    setCoverTypes([...new Set(response.data.coverOptions.map(opt => opt.coverType))]);
                }
            } catch (err) {
                console.error('Error fetching data:', err.message);
            }
        };

        if (productName) {
            getData();
        }
    }, [productName]);

    // Set Default Cover on Initial Load
    useEffect(() => {
        if (data?.coverOptions && data.coverOptions.length > 0) {
            const defaultCover = data.coverOptions[0];

            if (isFirstRender.current) {
                setCoverSelection(defaultCover);
                setCoverImage(defaultCover?.url);
                isFirstRender.current = false;
            }
        }
    }, [data]);

    // Set Available Sizes
    useEffect(() => {
        if (data) {
            console.log("Data:", data)
            const sizeOptions = Array.from(new Set(data.productOptions.map((item) => item.size )))
            console.log("Size Options:", sizeOptions)
            setSizeAvailable(sizeOptions)
            
            if (sizeAvailable.length > 0) {
                // Set the first available size as the default
                setSizeSelection(sizeOptions[0]);
    
                // Set the price for the default size
                const selectedProductOption = data.productOptions.find(option => option.size === sizes[0]);
                if (selectedProductOption) {
                    setPrice(selectedProductOption.price);
                }
            }
        } else {
            // Handle the case where productOptions is not an array (loading or error state)
            console.warn('productOptions is undefined or not an array');
        }
    }, [data]);

    // Filtered Cover Images
    const filteredImages = data?.coverOptions?.filter(option => option.coverType === coverSelection?.coverType) || [];

    return (
        <main>
            <EmptyNav />
            {data === null ? (
                <div style={{ margin: '10px auto' }}>
                    <ReactLoading size={50} type='spin' />
                </div>
            ) : (
                <div className='productArea'>
                    {/* Image Display */}
                    <div className='itemImage'>
                        {images.length > 0 && (  // Only display if there are images
                            <>
                                <FaChevronCircleLeft color='#967f71' id='leftArrow' size={25} onClick={handleRearPhoto} />
                                <img src={images[imageIndex]?.url || ''} alt="Product" style={{ width: '85%' }} />
                                <FaChevronCircleRight color='#967f71' id='rightArrow' size={25} onClick={handleForwardPhoto} />
                                <div className='imageActiveCircle'>
                                    {images.map((item, key) => (
                                        <div style={{ display: 'flex' }} key={key}>
                                            {imageIndex === key ? (
                                                <GoDotFill size={20} color='#967f71' />
                                            ) : (
                                                <GoDot size={20} color='#967f71' />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>

                    {/* Product Info */}
                    <div className='itemDescription' style={{ marginTop: '100px' }}>
                        <h1>{data.productName}</h1>
                        <p>Choose Your Cover</p>

                        <div className='materialType'>
                            {coverTypes.length > 0 && coverTypes.map((item, index) => (
                                <h6
                                    key={index}
                                    id={item}
                                    onClick={() => handleCoverType(item)}
                                    className={coverSelection?.coverType === item ? 'active' : ''}
                                >
                                    {item}
                                </h6>
                            ))}
                        </div>

                        <div className='coverImages'>
                            {coverSelection && filteredImages.length > 0 ? (
                                filteredImages.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image.url}
                                        alt={image.coverName}
                                        className={`cover-image ${coverImage === image.url ? 'selected' : ''}`}
                                        onClick={() => handleCoverImage(image.url, image.coverName)}
                                    />
                                ))
                            ) : coverSelection ? (
                                <p>No images for this cover type.</p>
                            ) : (
                                <p>Select a cover type to see images.</p>
                            )}
                        </div>

                        <div className="sizeContainer">
                            <p>Choose Your Size</p>
                            <div className="sizeArea">
                                {sizeAvailable.length > 0 ? (
                                    sizeAvailable.map((size, index) => (
                                        <p
                                            key={index}
                                            id={size}
                                            onClick={handleSize}
                                            className={sizeSelection === size ? 'active' : undefined}
                                        >
                                            {size}
                                        </p>
                                    ))
                                ) : (
                                    <p>No sizes available.</p>
                                )}
                            </div>
                        </div>

                        <div className='totalPrice'>
                            <h4 style={{ textAlign: 'center' }}>Item Price: ${price}</h4>
                            <button onClick={handleAddToCart} style={{ backgroundColor: 'royalblue', color: 'white' }}>Add to Cart</button>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
}
