import { FaShoppingCart } from 'react-icons/fa'
import logo from '../../assets/images/logo-black-500.png'

export default function EmptyNav() {

    return(
        <div className="navbar">
            <a href='/' >
                <img src={logo} width={175} alt='logo image' />
            </a>
            
        </div>
    )
}