import { useState, useEffect } from 'react';
import './BridgeOverlay.css'
export default function BridgeOverlay(props) {
  const [visible, setVisible] = useState(false);

  // Trigger the animation when the component mounts
  useEffect(() => {
    setTimeout(() => setVisible(true), 100); // Adding a slight delay before showing the h4
  }, []);

  return (
    <div
      style={{
        backgroundImage:
          `linear-gradient(to bottom, ${props.connectTopColor} 0%, ${props.backgroundColor} 30%, ${props.backgroundColor} 50%, ${props.connectBottomColor} 75%, transparent 100%)`,
        height: `${props.height}`,
        width: '100%',
        zIndex: 5,
        marginTop: `${props.marginTop}`,
        marginBottom: `${props.marginBottom}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative', // Ensure it's positioned correctly between the divs
        overflow: 'hidden', // To ensure h4 doesn’t go outside the container during animation
      }}
    >
      <h4
        style={{
          color: `${props.color}`,
          fontWeight: 'bold',
          textAlign: 'center',
          margin: 0, // To prevent any default margin interfering
          transform: visible ? 'translateY(0)' : 'translateY(100%)', // Start below the container
          opacity: visible ? 1 : 0, // Fade in effect
          transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out', // Ease-in-out transition for both transform and opacity
        }}
      >
        {props.feature}
      </h4>
    </div>
  );
}
