import { useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// User Context *****************************
export const UserContext = createContext({});

// Function *******************************
export default function UserProvider({ children }) {
  const [userState, setUserState] = useState({
    user: JSON.parse(localStorage.getItem('user')) || null,
    token: localStorage.getItem('token') || '',
    error: null,  // Optionally track errors
  });
  

  // Set Axios defaults for future requests when a user logs in
  function setAxiosAuthToken(token) {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }

  // When the component mounts, ensure axios is configured correctly with the token
  if (userState.token) {
    setAxiosAuthToken(userState.token);
  }

  // Signup function
  async function signup(userData) {
    try {
      const res = await axios.post('http://localhost:9000/auth/signup', userData);
      const { user, token } = res.data;
      if(res.status === 201){
        
      }
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));

      setUserState((prevUserState) => ({
        ...prevUserState,
        user,
        token,
        error: null,
      }));

      setAxiosAuthToken(token);  // Set token in axios for authenticated requests
    } catch (error) {
      console.log(error);
      setUserState((prevUserState) => ({
        ...prevUserState,
        error: error.response?.data?.message || 'Signup failed',
      }));
    }
  }

  // Login function
  async function login(credentials) {
    try {
      const res = await axios.post('http://localhost:9000/auth/login', credentials);
      const { user, token } = res.data;

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));

      setUserState((prevUserState) => ({
        ...prevUserState,
        user,
        token,
        error: null,
      }));

      setAxiosAuthToken(token);  // Set token in axios for authenticated requests
    } catch (error) {
      console.log(error);
      setUserState((prevUserState) => ({
        ...prevUserState,
        error: error.response?.data?.message || 'Login failed',
      }));
    }
  }

  // Logout function
  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUserState({
      user: null,
      token: '',
      error: null,
    });
    setAxiosAuthToken(null);  // Clear token from axios
    
  }

  // Check if user is logged in
  function checkUser() {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    if (token && user) {
      setUserState({
        user,
        token,
        error: null,
      });
      setAxiosAuthToken(token);  // Set token in axios for authenticated requests
    } else {
      logout();  // Clear any invalid data if not authenticated
    }
  }

  return (
    <UserContext.Provider
      value={{
        ...userState,
        signup,
        login,
        logout,
        checkUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
