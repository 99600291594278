import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import {useContext} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import { createBrowserRouter, RouterProvider, useOutletContext, redirect, useParams } from 'react-router-dom'
import Modal from 'react-modal'

import { UserContext } from './context/UserProvider.jsx'


Modal.setAppElement('#root')
// PROVIDERS ***************************************
import UserProvider from './context/UserProvider.jsx'
// Loaders *******************************************


/// ICONS ************************************************


// Layouts *************************************************
import RootLayout from './pages/RootLayout.jsx'

// Import Pages *********************************************
import HomePage from './pages/home/HomePage.jsx'
import ErrorPage from './errorPage.jsx'
import About from './pages/about/AboutPage.jsx'
import Login from './pages/login/LoginPage.jsx'
import CreateAccount from './pages/create-account/Create-AccountPage.jsx'
import WallArt from './pages/wallArt/wallArtPage.jsx'
import Albums from './pages/albums/albumsPage.jsx'
import Editing from './pages/editing/editingPage.jsx'
import ProductPage from './pages/productPage/productPage.jsx'
import AlbumProductPage from './pages/productPage/albumProductPage.jsx'

// Company Page imports *********************************************
import ProtectedRoute from './pages/ProtectedRoute.jsx'
import AdminProductPage from './pages/admin/product/adminProductPage.jsx'
import SharePage from './pages/share/share.jsx'
import ReferAFriendPage from './pages/refer-a-friend/refer-a-friend.jsx'
import AlbumsOrder from './pages/albums/albumsOrder.jsx'
import AddProductPage from './pages/admin/product/addProductPage.jsx'
import CartPage from './pages/cart/cartPage.jsx'
import AddEditingPage from './pages/editing/addEditingPage.jsx'
import GalleryUploadPage from './pages/productPage/galleryUploadPage.jsx'
import MyOrdersPage from './pages/orders/MyOrdersPage.jsx'
import OrderTrackingPage from './pages/admin/orders/orderTrackingPage.jsx'
import AddAlbumsPage from './pages/admin/product/addAlbumsPage.jsx'
import LearnMorePage from './pages/learn-more/LearnMorePage.jsx'
import {CartProvider} from './context/CartContext.jsx'
import ThankYouPage from './pages/thank-you/ThankYouPage.jsx'
import CheckoutForm from './components/checkout/CheckoutForm.jsx'
import CompletePage from './pages/thank-you/CompletePage.jsx'
import EditorsPage from './pages/admin/editors/editorsPage.jsx'
import ProductInfoPage from './pages/productPage/ProductInfoPage.jsx'
import OrderDetailsPage from './pages/admin/orders/orderDetailsPage.jsx'

// marketing pages ************************************************
import PreLaunchSpecialPage from './pages/landingPages/PreLaunch/PreLaunchSale.jsx'
import CaptureMemoriesPage from './pages/landingPages/CaptureMemories/CaptureMemories.jsx'


const router = createBrowserRouter([
 { 
  path: "/",
  element: <RootLayout/>,
  errorElement: <ErrorPage/>,
  children: [
   {path: '/', element: <HomePage/>},
   {path: '/albums', element: <Albums/>  },
   {path: '/albums/add-editing', element: <AddEditingPage/>  },
   {path: '/albums/select', element: <AlbumsOrder/>  },
   {path: '/albums/:albumName', element: <ProductInfoPage/>  },
   {path: '/products/:productName', element: <ProductInfoPage/>  },
   {path: '/shop/albums/:albumName', element: <AlbumProductPage/>  },
   {path: '/shop/products/:productName', element: <ProductPage/>  },
   {path: '/editing', element: <Editing/>},
   {path: '/wall-art', element: <WallArt/>},
   {path: '/learn-more', element: <LearnMorePage/>  },
    {path: '/About', element: <About/>},
    {path: '/thank-you', element: <ThankYouPage/>},
    {path: '/login', element: <Login/>},
    {path: '/addAlbums', element: <AddAlbumsPage/>},
    {path: '/cart', element: <CartPage/>},
    {path: '/checkout', element: <CheckoutForm/>},
    {path: '/complete', element: <CompletePage/>},
    {path: '/sale/pre-launch-special', element: <PreLaunchSpecialPage/>},
    {path: '/sale/capture-memories', element: <CaptureMemoriesPage/>},
    {path: '/:userId/orders', element: <MyOrdersPage/>},
    {path: '/:userId/:orderId/gallery-upload', element: <GalleryUploadPage/>},
    {path: '/admin/outstanding-orders', element: <OrderTrackingPage/> },
    {path: '/admin/products', element: <AdminProductPage/>},
    {path: '/admin/editors', element: <EditorsPage/>},
    {path: '/admin/add-products', element: <AddProductPage/>},
    {path: '/admin/:orderId', element: <OrderDetailsPage/>},
    {path: '/:name', element: <ProductInfoPage/>},
    {
      path: '/create-account', 
      element: <CreateAccount/>,
      
    },
    {path: '/Pre-Launch-Special/share', element: <SharePage/>},
    {path: '/Pre-Launch-Special/refer-a-friend', element: <ReferAFriendPage/>}
  ]
  
},

])

ReactDOM.createRoot(document.getElementById('root')).render(
  
  
    <UserProvider>
      <CartProvider>
          <RouterProvider router={router} />
      </CartProvider>
    </UserProvider>
)


