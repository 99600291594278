import { useState } from "react";
import { Link } from "react-router-dom";
import './LearnMorePage.css'
import ReactLoading from 'react-loading'

import Navbar from "../../components/navbars/Navbar";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { GoDot, GoDotFill } from "react-icons/go";


// IMAGES *************************
import Lux from '../../assets/images/Lux-Specialty-RusticBrass.jpg'
import Gallery from '../../assets/images/Gallery/Gallery-Ivory.jpg'
import Economy from '../../assets/images/Economy/EconomyAlbum_005.jpg'
import PressBook from '../../assets/images/Press/Press-Book-Corner.jpeg'
import Talon from '../../assets/images/talon.jpg'
import USFlag from '../../assets/images/USFlag.jpg'

export default function LearnMorePage() {
    const [loading, setLoading] = useState(false)
    const [imageIndex, setImageIndex] = useState(0);
    const [imageIndex2, setImageIndex2] = useState(0);
    const [imageIndex3, setImageIndex3] = useState(0);
    
    const images = [Lux, Gallery, Economy, PressBook]
    const images2 =[Lux, Gallery, Economy, PressBook]
    const images3 =[USFlag, Talon]

// FUNCTIONS ****************************************
function handleForwardPhoto() {
    setImageIndex(index => (index === images.length - 1 ? 0 : index + 1));
}

function handleRearPhoto() {
    setImageIndex(index => (index === 0 ? images.length - 1 : index - 1));
}
function handleForwardPhoto2() {
    setImageIndex2(index2 => (index2 === images2.length - 1 ? 0 : index2 + 1));
}

function handleRearPhoto2() {
    setImageIndex2(index2 => (index2 === 0 ? images2.length - 1 : index2 - 1));
}
function handleForwardPhoto3() {
    setImageIndex3(index3 => (index3 === images3.length - 1 ? 0 : index3 + 1));
}

function handleRearPhoto3() {
    setImageIndex3(index3 => (index3 === 0 ? images3.length - 1 : index3 - 1));
}

// useEffect *****************************************************


    return (
        <main>
            <Navbar />        
                <div className='aboveFold'>
                    <div className='itemImage'>
                        <FaChevronCircleLeft color='#967f71' id='leftArrow' size={25} onClick={handleRearPhoto} />

                        <img
                            src={images[imageIndex]}
                            alt="Album"
                            style={{borderRadius: '15px'}}
                        />

                        <FaChevronCircleRight color='#967f71' id='rightArrow' size={25} onClick={handleForwardPhoto} />

                        <div className='imageActiveCircle'>
                            {images.map((item, key) => (
                                <div style={{ display: 'flex' }} key={key}>
                                    {imageIndex === key ? (
                                        <GoDotFill size={20} color='#967f71' />
                                    ) : (
                                        <GoDot size={20} color='#967f71' />
                                    )}
                                </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="talkingPoint">
                        <h2>Photos you don't know what to do with?</h2>
                        <h4>Select an album or wall-art, upload your images, we design, build and ship it to you</h4>
                    </div>
                </div>
                <div className='aboveFold'>
                <div className="talkingPoint">
                        <h2>Our team will do the heavy lifting</h2>
                        <h4>Our professionals design, edit and build your album or wall art.</h4>
                    </div>
                    <div className='itemImage'>
                        <FaChevronCircleLeft color='#967f71' id='leftArrow' size={25} onClick={handleRearPhoto2} />

                        <img
                            src={images2[imageIndex2]}
                            alt="Album"
                            style={{borderRadius: '15px'}}
                        />

                        <FaChevronCircleRight color='#967f71' id='rightArrow' size={25} onClick={handleForwardPhoto2} />

                        <div className='imageActiveCircle'>
                            {images2.map((item, key) => (
                                <div style={{ display: 'flex' }} key={key}>
                                    {imageIndex2 === key ? (
                                        <GoDotFill size={20} color='#967f71' />
                                    ) : (
                                        <GoDot size={20} color='#967f71' />
                                    )}
                                </div>
                                ))
                            }
                        </div>
                    </div>
                   
                </div>
                <div className='aboveFold' style={{backgroundColor: '#3c3c3c', border: '0px', borderRadius: '15px', width: '99%', margin: '10px auto'}}>
               
                    <div className='itemImage'>
                        <FaChevronCircleLeft color='#967f71' id='leftArrow' size={25} onClick={handleRearPhoto3} />

                        <img
                            src={images3[imageIndex3]}
                            alt="Album"
                            style={{borderRadius: '15px'}}
                        />

                        <FaChevronCircleRight color='#967f71' id='rightArrow' size={25} onClick={handleForwardPhoto3} />

                        <div className='imageActiveCircle'>
                            {images3.map((item, key) => (
                                <div style={{ display: 'flex' }} key={key}>
                                    {imageIndex3 === key ? (
                                        <GoDotFill size={20} color='#967f71' />
                                    ) : (
                                        <GoDot size={20} color='#967f71' />
                                    )}
                                </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="talkingPoint">
                        <h2 style={{color: 'white'}}>A Veteran Owned Business, <strong>Made in the USA</strong></h2>
                        <h4 style={{color: 'white'}}>Albums, products and editing... done right here.</h4>
                    </div>
                   
                </div>
                <Link to="/albums/select">
                    <button>Select an Album</button>
                </Link>
            
        </main>
    );
}
