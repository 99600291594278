import './fullPageCard.css'
import { Link } from 'react-router-dom'

export default function FullPageCard(props) {

    return (
        <div 
            className="fullPageCard"
            style={{backgroundImage: `url(${props.image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover', 
                
                 }} 
        >
            
            <div className='titleArea'>
                <h2 style={{fontSize: '2.5rem', fontWeight: 600}}>{props.title}</h2>
                <h3 style={{color: 'white'}}>{props.price}</h3>
                <h6 style={{textAlign: 'center', fontWeight: 'bold', color: 'white'}}>{props.line2}</h6>
            </div>
            <div className='featureArea'>
                <div className='feature'>
                    
                </div>
                <div className='feature'></div>
                <div className='feature'></div>

            </div>
            
            <div className="buttonArea">
                <Link to={props.orderLink}>
                    <button className='button1'>Order Now</button>
                </Link>
                <Link to={props.learnLink}>
                    <button className='button2'>Learn More</button>
                </Link>
                
            </div>
        </div>
    )
}