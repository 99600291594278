import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import './ProductInfoPage.css';
import Navbar from '../../components/navbars/Navbar';
import ProductInfoMain from '../../components/productInfo/InfoMain/ProductInfoMain';
import BridgeOverlay from '../../components/bridgeOverlay/BridgeOverlay';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // For arrows
import CoverMaterialInfo from "../../components/productInfo/CoverMaterial/CoverMaterialInfo";
import FeatureFloat from "../../components/productInfo/FeatureFloat/FeatureFloat";
import ReactGA from 'react-ga4'
export default function ProductInfoPage() {
// GA ************************************************************************
// State *************************************
const [showOrderNow, setShowOrderNow] = useState(false);
const [data, setData] = useState({}); // Initialize as an object
const [coverTypes, setCoverTypes] = useState([]);
const [coverOptionNum, setCoverOptionsNum] = useState('')
const [coverSelection, setCoverSelection] = useState(null);  // Selected cover
const [coverImage, setCoverImage] = useState(null);  // Selected cover image URL
const [selectedCoverType, setSelectedCoverType] = useState(''); // Currently selected cover type
const [currentImageIndex, setCurrentImageIndex] = useState(0); // For the image carousel
const [loading, setLoading] = useState(false);
const { albumName, productName } = useParams();

{albumName && ReactGA.send({ hitType: "pageview", page:  `/albums/${albumName}`, title: `${albumName} view` }) }
{productName && ReactGA.send({ hitType: "pageview", page:  `/products/${productName}`, title: `${productName} view` }) }
    // Automatically rotate image every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            if (!loading && data.coverOptions && data.coverOptions.length > 0) {
                handleNextImage();
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [data.coverOptions, currentImageIndex, loading]);

    useEffect(() => {
        const handleScroll = () => {
            const secondSection = document.getElementById('second-section');
    
            if (secondSection) {
                const rect = secondSection.getBoundingClientRect();
                const windowHeight = window.innerHeight;
    
                console.log('Scroll detected'); // Add this to check if the scroll is detected
                console.log('rect.top:', rect.top, 'windowHeight:', windowHeight);
    
                // Check if the second section is within the viewport
                if (rect.top <=  350) {
                    setShowOrderNow(true);  // Show the button when the section is visible
                } else {
                    setShowOrderNow(false);  // Hide the button when the section is not in the viewport
                }
            }
        };
    
        // Attach scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    

    // Functions for carousel image navigation
    const handleNextImage = () => {
        if (filteredCoverOptions.length > 0) {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % filteredCoverOptions.length);
        }
    };

    const handlePrevImage = () => {
        if (filteredCoverOptions.length > 0) {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === 0 ? filteredCoverOptions.length - 1 : prevIndex - 1
            );
        }
    };

    // Filter cover options by selected cover type
    const filteredCoverOptions = Array.isArray(data.coverOptions) 
    ? data.coverOptions.filter(option => option.coverType === selectedCoverType) 
    : [];


    // Handle cover type selection
    const handleCoverType = (coverType) => {
        if (Array.isArray(data.coverOptions)) {
            const filteredCovers = data.coverOptions.filter(option => option.coverType === coverType);
            
            if (filteredCovers.length > 0) {
                const defaultCover = filteredCovers[0];
                setCoverSelection({
                    coverType: defaultCover.coverType,
                    coverName: defaultCover.coverName,
                    url: defaultCover.url,
                });
                setCoverImage(defaultCover.url);
                setSelectedCoverType(coverType);
            }
        } else {
            console.error("coverOptions is not an array or is missing.");
        }
    };
    

    // Handle cover image selection
    const handleCoverImage = (url, coverName) => {
        if (data.coverOptions) {
            const selectedCover = data.coverOptions.find(option =>
                option.coverName === coverName && option.coverType === coverSelection.coverType
            );

            if (selectedCover) {
                setCoverSelection({
                    ...coverSelection,
                    coverName: selectedCover.coverName,
                    url: url,
                });
                setCoverImage(url);
            }
        }
    };

    // Fetch album/product data and set cover types
    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            try {
                let response;
                if (albumName) {
                    response = await axios.get(`${import.meta.env.VITE_API}/albums/${albumName}`);
                } else if (productName) {
                    response = await axios.get(`${import.meta.env.VITE_API}/products/${productName}`);
                }

                console.log("API Response:", response.data); // Check the structure of response.data

                if (response.data) {
                    setData(response.data); // Store the object data

                    // Ensure coverOptions is an array
                    const coverOptions = response.data.coverOptions || []; // Use an empty array as fallback
                    setCoverOptionsNum(coverOptions.length)
                    if (Array.isArray(coverOptions)) {
                        // Extract unique cover types from coverOptions
                        const uniqueCoverTypes = [...new Set(coverOptions.map(option => option.coverType))];
                        setCoverTypes(uniqueCoverTypes);

                        // Automatically set the first cover type as default
                        if (uniqueCoverTypes.length > 0) {
                            setSelectedCoverType(uniqueCoverTypes[0]); // Default to the first cover type
                            handleCoverType(uniqueCoverTypes[0]); // Set the first cover type and related image
                        }
                    } else {
                        console.error("Cover options are not an array.");
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setData({});
            } finally {
                setLoading(false);
            }
        };

        if (albumName || productName) {
            getData();
        }
    }, [albumName, productName]);

    return (
        <main >
            <Navbar />

            {/* Main Product Info Section */}
            <ProductInfoMain
                imageUrl={`${data.mainImage}`}
                name={data.albumName || 'Lux'}
                startingPrice={849}
                included={"Album Design Included"}
                feature1={'Pleated'}
                feature1Des={'Corners'}
                feature2={'20/30'}
                feature2Des={'Page Options'}
                feature3={coverOptionNum}
                feature3Des={"Cover Options"}
                buttonLink={`/shop/albums/${albumName}` || `/shop/products/${productName}`}
                
                
            />
            <BridgeOverlay 
                feature={"Cover Options"} 
                color={'white'}
                connectTopColor={'transparent'}
                backgroundColor={'black'} 
                connectBottomColor={'black'}
                height={'500px'}
                marginTop={'-175px'}
                marginBottom={'-50px'}
                 
            />
            <div id="second-section">
                <CoverMaterialInfo 
                    data = {data}
                    
                />
            </div>
            <BridgeOverlay 
                feature={"Construction"} 
                color={'#3c3c3c'}
                backgroundColor={'white'} 
                height={'150px'}
                marginTop={'10%'}
                marginBottom={''}
                connectTopColor={'white'}
                connectBottomColor={'white'}
                 
            />
            {albumName === "Lux" &&
             <>
             <FeatureFloat 
                backgroundColor={'white'}
                imageUrl={'https://albumsandart-product.s3.amazonaws.com/Albums/Lux/Lux-Specialty-RusticBrass.jpg'}
                textColor={'#3c3c3c'} 
                title={'Artisan Materials'}
                description={'Craftsmanship begins with the finest materials.  Leather and material is carefully picked to look beautiful and last the test of time.'}
                reverse={false}
                />
                <FeatureFloat 
                    backgroundColor={'white'}
                    imageUrl={'https://albumsandart-product.s3.amazonaws.com/hand-crafted/Handcrafted+Albums.png'}
                    textColor={'#3c3c3c'} 
                    title={'Hand Crafted'}
                    description={'Each album receives meticulous care and attention to detail for you to enjoy for a lifetime.'}
                    reverse={true}
                />
                <FeatureFloat 
                    backgroundColor={'white'}
                    imageUrl={'https://albumsandart-product.s3.amazonaws.com/Albums/pleated-corners/pleated-corners.jpg'}
                    textColor={'#3c3c3c'} 
                    title={'Pleated Corners'}
                    description={'A small detail that goes a long way in regards to the quality of your album with our leather covers.  '}
                    reverse={false}
                    />
            </>
            }
            { albumName === "Gallery" &&
                <FeatureFloat 
                    backgroundColor = {'white'}
                    imageUrl={''}
                    textColor={'#3c3c3c'}
                    title={'Fabric and Faux Leather Options'}
                    description={'15 album cover options '}
                    reverse={false}
                />

            }
            { albumName === "economy" && 
                <FeatureFloat
                    backgroundColor={'white'}
                    imageUrl={'https://albumsandart-product.s3.amazonaws.com/Albums/Economy/EconomyAlbum_005.jpg'}
                    textColor={'#3c3c3c'}
                    title={'Simple & Budget Friendly'}
                    description={'A simple 20-page, 8x8 or 10x10, black leather album'}

                />
            }

            { albumName !== "press-book" &&
                <>
                <FeatureFloat 
                    backgroundColor={'white'}
                    imageUrl={'https://albumsandart-product.s3.amazonaws.com/Albums/lay-flat/lay-flat.jpg'}
                    textColor={'#3c3c3c'} 
                    title={'Lay Flat Design'}
                    description={'Seamless design for a beautifully displayed image.  Ensuring every detail is shown '}
                    reverse={true}
                    />
            
            
            </>
            }
            { albumName === 'press-book' && 
                <FeatureFloat 
                    backgroundColor={'white'}
                    imageUrl={'https://albumsandart-product.s3.amazonaws.com/Albums/Lux/Lux-Specialty-RusticBrass.jpg'}
                    textColor={'#3c3c3c'} 
                    title={'Magazine Style Pages'}
                    description={'Cost effect for a budget friendly option and allows for a slim option great for storage'}
                    reverse={false}
                    />
            }
            <FeatureFloat 
                backgroundColor={'white'}
                imageUrl={'https://albumsandart-product.s3.amazonaws.com/Albums/Lux/Lux-Specialty-RusticBrass.jpg'}
                textColor={'#3c3c3c'} 
                title={'Print Quality'}
                description={'Acid free inks with a pH neutral balace allowing for long lasting prints that lasts a lifetime'}
                reverse={false}
            />
            {albumName &&
            <FeatureFloat 
                backgroundColor={'white'}
                imageUrl={'https://albumsandart-product.s3.amazonaws.com/Editing/album-design.PNG'}
                textColor={'#3c3c3c'} 
                title={'Album Design Included'}
                description={'All of our albums have design included with your purchaseal'}
                reverse={true}
            />
            }
            
          

            {/* Order Now Button */}
            <div
                style={{
                    display: 'flex',
                    backgroundColor: 'transparent',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    bottom: '25px', 
                    left: '50%', 
                    transform: 'translate(-50%)',
                    zIndex: 100
                    
                    
                }}
            >
                {albumName && showOrderNow &&
                <Link to={`/shop/albums/${data.albumName }`}>
                    <button id="button3" style={{ width: '250px', fontSize: '16px', padding: '5px 10px' }}>
                        Order Now
                    </button>
                </Link>
                }
                {productName && showOrderNow &&
                <Link to={`/shop/products/${data.productName }`}>
                    <button id="button3" style={{ width: '250px', fontSize: '16px', padding: '5px 10px' }}>
                        Order Now
                    </button>
                </Link>
                }
            </div>
        </main>
    );
}
