import React from 'react'
import { useContext } from 'react'
import { Container } from 'react-bootstrap'
import Navbar from '../../components/navbars/Navbar'
import FullPageCard from '../../components/cards/fullPageCard'
import PreLaunchForm from '../../components/forms/PreLaunchForm'
import './home.css'
import ReactPlayer from 'react-player/lazy'
import Album1 from '../../assets/images/Lux-Specialty-RusticBrass.jpg'
import Wedding from '../../assets/images/Estates.jpg'
import Wedding2 from '../../assets/images/Meridian.jpg'
import Wedding3 from '../../assets/images/Wedding-Photography.jpg'
import ImageBox from '../../assets/images/product/imagebox/ImageBox3.jpg'
import { FaLongArrowAltRight } from "react-icons/fa";
import AdminBar from '../../components/navbars/adminBar'
import { UserContext } from '../../context/UserProvider'

export default function HomePage() {

    return (
    <main> 
                
        <Navbar/>
        <div className='processSection'>
            <p className='process' style={{padding: '5px'}}>Select Album & Cover</p>
            <FaLongArrowAltRight className='arrowIcon' size={55}/>
            <p className='process' style={{padding: '5px'}}>Order</p>
            <FaLongArrowAltRight  className='arrowIcon' size={55}/>
            <p className='process' style={{padding: '5px'}}>Upload Images</p>
            <FaLongArrowAltRight  className='arrowIcon' size={55}/>
            <p className='process' style={{padding: '5px'}}>We Edit, Design & Ship</p>
        </div>
            
        <FullPageCard 
            image={Album1}
            title='Lux'
            line2='Album Design Included'
            price='From:  $899'
            learnLink='/albums/Lux'  
            orderLink='shop/albums/lux'       
        />
        <FullPageCard 
            image={'https://albumsandart-product.s3.amazonaws.com/Albums/Gallery/Gallery-Ivory.jpg'}
            title='Gallery'
            line2='Album Design Included'
            price='From:  $649'
            learnLink='/Gallery'  
            orderLink='/albums/Gallery'       
        />
        <FullPageCard 
            image={'https://albumsandart-product.s3.amazonaws.com/Albums/Economy/EconomyAlbum_005.jpg'}
            title='Economy'
            line2='Album Design Included'
            price='From:  $449'
            learnLink='/economy'  
            orderLink='/albums/Economy'       
        />
        <FullPageCard 
            image={'https://albumsandart-product.s3.amazonaws.com/Albums/Press-Book/Press-Book-Corner.jpeg'}
            title='Press Book'
            line2='Album Design Included'
            price='From:  $249'
            learnLink='/press-book'  
            orderLink='/albums/press-book'       
        />
        
        <FullPageCard 
            image={Wedding2}
            title='Editing (Coming Soon)'
            line2='Add Some Beauty Edits, Spot Removal or Our Unique Lux Edit'
            price='From:  $9' 
            learnLink='/editing'         
            orderLink="/albums/select"
        />
        <FullPageCard 
            image={Wedding}
            title='Wall Art, Prints and More! (COMING SOON)'
            line2='Metals, Acrylics, Pre-Framed Options'
            price='From:  $299' 
            learnLink=""
            orderLink=""      
        />
        {/* <FullPageCard 
            image={ImageBox}
            title='Image Boxes'
            line2='(Coming Soon!)'
            price='From:  $299'
            learnLink=""
            orderLink=""            
        /> */}
            
      
</main>
    )
}