import { useState } from "react";
import './addEditing.css'


export default function AddEditingPage(){
    

    return(
        <main>
            <EmptyNav/>
            <div className='aboveFold'>
                <div className='itemImage'>
                    <FaChevronCircleLeft color='#967f71' id='leftArrow' size={25} onClick={handleRearPhoto}/>

                    <img src={images[imageIndex]}></img>
                   
                    <FaChevronCircleRight color='#967f71' id='rightArrow' size={25} onClick={handleForwardPhoto}/>
                    <div className='imageActiveCircle'>
                        {images.map((item, key)=> {
                            return(
                                <div style={{display: 'flex'}}>
                                {imageIndex === key? <GoDotFill size={20} color='#967f71' /> : <GoDot size={20} color='#967f71'/>}
                                </div>
                            )
                               
                            
                        })}
                        
                    </div>
                </div>
                <div className='itemDescription'>
                    <h1>Leather Albums{props.productTitle}</h1>
                    <p>30 pages {props.basePages}</p>
                    <p>Choose Your Cover</p>
                    <CoverMaterial/>
                    <SelectAlbumSize 
                        basePrice={basePrice}
                        basePage={basePage}

                    />
                    <div>
            <div className="addPages">
                <select onChange={handleAddPage}>
                    <option value="">Add Pages</option>
                    <option value="2">2</option>
                    <option value="4">4</option>
                    <option value="6">6</option>
                    <option value="8">8</option>
                    <option value="10">10</option>
                    <option value="12">12</option>
                    <option value="14">14</option>
                    <option value="16">16</option>
                    <option value="18">18</option>
                    <option value="20">20</option>
                </select>
            </div>
            <div className="pageCount">
                <h6>Additional Cost: ${Number(addPages) * 15}</h6>
                <h6>Max Images for Design: {Number(totalPages) * 2}</h6>
                <h5>Total Pages: {totalPages}</h5>
            </div>
        </div>
        <div>
            
            <div>
                <select onChange={handleEditPrice} >
                    <option value="0" id='select'>Select Additional Editing</option>
                    <option value="199" id="Pro">Pro +199</option>
                    <option value="299" id="Pro Plus">Pro Plus + $299</option>
                    <option value="599" id="Lux">Lux +599</option>
                    <option value="799" id="Black Label">Black Label +799</option>
                </select>
                
                <div className="optionList">
                    
                    {editSelect === "Pro"? pro1.map((edit) => {
                        return(
                            
                            <div>
                            <li>{edit}</li>
                            </div>    
                            )
                            
                        }) 
                        :null
                        
                    }
                    {editSelect === "Pro Plus"? pro2.map((edit) => {
                        return(
                            
                            <div>
                            <li>{edit}</li>
                            </div>    
                            )
                            
                        }) 
                        :null
                    
                    }
                    {editSelect === "Lux"? lux1.map((edit) => {
                        return(
                            
                            <div>
                            <li>{edit}</li>
                            </div>    
                            )
                           
                        }) 
                        :null
                        
                    }
                    {editSelect === "Black Label"? lux2.map((edit) => {
                        return(

                            <div>
                            <li>{edit}</li>
                            </div>    
                            )
                            
                        }) 
                        :null
                        
                    }
                    
                    <div className='totalPrice'>
                        <h4>Item Price: ${Number(basePrice)+(Number(pageCost)*Number(addPages))+Number(editPrice)}</h4>
                        <button onClick={submitToCart}>Add to Cart</button>
                    </div>
                </div>
                    
            </div>
        </div>
                   
                </div>
            </div>
        </main>
    )
}