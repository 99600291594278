
import { useState, useRef, useEffect, useContext } from 'react'

import { UserContext } from '../../context/UserProvider'
import './createAccount.css'
import {useNavigate } from 'react-router-dom'
import Modal from 'react-modal'
import Navbar from '../../components/navbars/Navbar'
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { Navigate } from 'react-router-dom'
import axios from 'axios'

const passREGEX = /^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*[0-9].*[0-9])(?=.*[!@#$%^&*].*[!@#$%^&*].{12, 32})$/

const regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"


export default function CreateAccount () {
const navigate = useNavigate()
  
 // Refs **********************************
 const userRef = useRef()
 const errRef = useRef()
 const firstRef = useRef() 

 //Context ************************
 const { signup, token, user } = useContext(UserContext)
// Form State *************************************
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [verify, setVerify] = useState('')
  const [eye, setEye] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  // Checks States
  const [test, setTest] = useState('')
  const [isValidated, setIsValidated] = useState(false)
  const [length, setLength] = useState('Password contains 12 or more characters')
  const [capital, setCapital] = useState('2 Uppercase letters - minimum')
  const [symbols, setSymbols] = useState('2 special characters minimum !@#$%^&*')
  const [lowerCase, setLowerCase] = useState('2 lower case letters - minimum')
  const [numbers, setNumbers] = useState('2 numbers minimum')
  const [match, setMatch] = useState('The passwords must match')

  const stateSelection = ['Select Your State', 'AL', 'TX']

  function handleEye() {
    setEye(eye => !eye)
  }
  // validation 
  // const [lowerValidated, setLowerValidated] = useState(false)
  // const [upperValidated, setUpperValidated] = useState(false)
  // const [numberValidated, setNumberValidated] = useState(false)
  // const [specialValidated, setSpecialValidated] = useState(false)
  // const [lengthValidated, setLengthValidated] = useState(false)
  
  // const [errMsg, setErrMsg] = useState('')
  // const [success, setSuccess] = useState(false)
   const [errors, setErrors]= useState([])

  // function formValidation() {
  //   let errors = {};
  //   if (!firstName) {
  //     errors.firstName = 'Please enter a first name'
  //   }
  //   if (!lastName) {
  //     errors.lastName = 'Please enter a last name'
  //   }
  //   if (!phone) {
  //     errors.phone = 'Please enter a phone number'
  //   }
  //   else if (phone <10 ) {
  //    errors.phone = 'Please provide a valid phone number'
  //   }
  //   if (!password) {
  //     errors.password = 'Please enter a password'
  //   }
  //   else if (password != regex) {
  //     errors.password = 'Please enter a valid password'
  //   }
  //   if (!companyName) {
  //     errors.companyName = 'Please enter a company name'
  //   }
  //   setErrors(errors)
  //   setIsValid (Object.keys(errors).length === 0)
  //  }



  // UseEffects **************************************
  useEffect(() => {
    firstRef.current.focus()
  }, [])

  // useEffect(() => {
  //   const result = passREGEX.test(password)
  //   console.log(result)
  //   console.log(password)
  //   setIsValidated(result)
  //   const same = password === verify
  //   setMatch(same)
  // }, [password, verify])

  // useEffect(() => {
  //   setErrors("")
  // }, [companyName, firstName, lastName, phone, password, verify ])
function openModal() {
  setModalOpen(prev => !prev)
}



async function handleSignupSubmit(e) {
  e.preventDefault();
  try {
      // Send the signup data to trigger Twilio verification, but do not create the user yet
      const res = await axios.post(`${import.meta.env.BASE_API}/auth/signup`, {
          email, password, phone, firstName, lastName
      });

      if (res.status === 200) {
          // Open the verification modal for user to input the code
          setModalOpen(true);
      }
  } catch (error) {
      console.error("Error sending signup data", error);
      setErrors("Error during signup. Please try again.");
  }
}

   // Handle Verification Code Submit
   async function handleVerificationSubmit(e) {
    e.preventDefault();
    try {
      const res = await axios.post(`${import.meta.env.BASE_API}/auth/verify`, { phone, code: verificationCode, firstName, lastName, email, password, shippingAddress: {street, city, state, zip} });
      
        // Verification successful
        if(res.status === 201) {
        navigate('/login'); // Redirect after successful verification
      }
      else {
        console.log('verification failed')
        setErrors('Verification failed.  Please try again')
      }
    } catch (error) {
      console.error("Error during verification", error);
      setErrors("Verification failed. Please try again.");
    }
  }
  
    return (
        <main >  
        <Navbar />          
            <div className='formBox'>
            <h1>Create Your Account</h1>
              <p ref={errRef} aria-live='assertive'>{errors}</p>
              <form onSubmit={handleSignupSubmit}>
                <input
                  type='text'
                  name='firstName'
                  placeholder='First Name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required={true}
                  tabIndex={1}
                  ref={firstRef}
                  autoComplete='off'
                ></input>
                <input
                  type='text'
                  name='lastName'
                  placeholder='Last Name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required={true}
                  tabIndex={2}
                  autoComplete='family-name'
                ></input>
                <input
                  type='tel'
                  name='phone'
                  placeholder='Phone Number'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value.replace(/\D/g, '').slice(0, 10))}  // Remove non-digits and limit to 10
                  required={true}
                  tabIndex={3}
                  autoComplete='tel'
                ></input>
                <input
                  type='email'
                  name='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required={true}
                  tabIndex={4}
                  autoComplete='email'
                ></input>
                {email !== "" && 
                <>
                  <label>Address</label>
                  <input 
                    type='text'
                    name='street'
                    placeholder='Street'
                    value={street}
                    aria-label='street'
                    onChange={(e) => setStreet(e.target.value)}
                    required
                    tabIndex={5}
                    ></input>
                  <input 
                    type='text'
                    name='city'
                    placeholder='City'
                    value={city}
                    aria-label='city'
                    onChange={(e) => setCity(e.target.value)}
                    required
                    tabIndex={6}
                    ></input>
                  <select 
                    name='state'
                    value={state}
                    aria-label='state'
                    onChange={(e) => setState(e.target.value)}
                    required
                    tabIndex={7}
                    >
                      {stateSelection.map((item, index) => {
                        return(
                          <option value={item} key={index} style={{color: '#3c3c3c'}}>{item}</option>
                        )

                      })}

                    </select>
                  <input 
                    type='text'
                    name='zip'
                    placeholder='Zip Code'
                    value={zip}
                    aria-label='zip code'
                    onChange={(e) => setZip(e.target.value)}
                    required
                    tabIndex={8}
                    ></input>
                </>
                }
                <input
                  type={eye? 'text': 'password'}
                  name='password'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required={true}
                  tabIndex={9}
                  autoComplete='new-password'
                ></input>
                <input
                  type={eye? 'text' : 'password'}
                  name='verifyPassword'
                  placeholder='Verify your password'
                  value={verify}
                  onChange={(e) => setVerify(e.target.value)}
                  required={true}
                  tabIndex={10}
                  
                ></input>
                <span>{eye? <IoMdEye onClick={handleEye}/> : <IoMdEyeOff onClick={handleEye}/>}</span>
                <input
                  type='text'
                  name='Address Verification'
                  placeholder='Address Verification'
                  value={test}
                  onChange={(e) => setTest(e.target.value)}
                  hidden={true}
                ></input>
                {/* {!isValidated && 
                  <div className='passwordValidation'>
                    {lengthValidated? null: <p>{length}</p>}
                    <p>{capital}</p>
                    <p>{lowerCase}</p>
                    <p>{numbers}</p>
                    <p>{symbols}</p>
                    <p>{verify === password? null : match}</p>
                  </div>
                } */}

                <button className='button1'>Create Account</button>
              </form>
             <br/>
             <a href='/login'>Already have an account?  Login</a>
            </div>
              {/* Verification Code Modal */}
                <Modal isOpen={modalOpen}>
                  <form onSubmit={handleVerificationSubmit}>
                    <h2>Enter Verification Code</h2>
                    <input
                      type="number"
                      placeholder="Verification Code"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      required
                    />
                    <button type="submit">Submit Verification Code</button>
                  </form>
                </Modal>
          </main>
        
    )
}

