import { createContext, useReducer } from 'react';

// Create the context
const CartContext = createContext();

// Initial state for the cart
const initialState = {
    cartItems: [], // Array to store both products and albums
};

// Reducer to handle cart actions
const cartReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TO_CART':
            // Check if an item (either album or product) with the same options already exists in the cart
            const existingItemIndex = state.cartItems.findIndex(item =>
                item.type === action.payload.type && // Check if the same type (album or product)
                item.id === action.payload.id && // Use id (product or album) to compare items
                item.size === action.payload.size &&
                (action.payload.type === 'album' 
                    ? item.coverSelection?.coverType === action.payload.coverSelection?.coverType &&
                      item.coverSelection?.coverName === action.payload.coverSelection?.coverName
                    : true) // For products, ignore coverSelection comparison
            );

            if (existingItemIndex !== -1) {
                // If the item exists, update its quantity
                const updatedCartItems = [...state.cartItems];
                updatedCartItems[existingItemIndex] = {
                    ...updatedCartItems[existingItemIndex],
                    quantity: updatedCartItems[existingItemIndex].quantity + action.payload.quantity
                };
                return {
                    ...state,
                    cartItems: updatedCartItems,
                };
            } else {
                // If no such item exists, add the new item to the cart
                return {
                    ...state,
                    cartItems: [...state.cartItems, action.payload],
                };
            }

        case 'REMOVE_FROM_CART':
            // Remove the item by matching its unique properties (id, size, and cover selection for albums)
            return {
                ...state,
                cartItems: state.cartItems.filter(item =>
                    !(item.type === action.payload.type &&
                    item.id === action.payload.id &&
                    item.size === action.payload.size &&
                    (action.payload.type === 'album'
                        ? item.coverSelection?.coverType === action.payload.coverSelection?.coverType &&
                          item.coverSelection?.coverName === action.payload.coverSelection?.coverName
                        : true)) // Ignore coverSelection for products
                ),
            };

        case 'UPDATE_QUANTITY':
            return {
                ...state,
                cartItems: state.cartItems.map(item =>
                    item.id === action.payload.id
                        ? { ...item, quantity: action.payload.quantity }
                        : item
                ),
            };

        default:
            return state;
    }
};

// CartProvider to manage cart state and dispatch actions
const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);

    const addToCart = (item) => {
        dispatch({ type: 'ADD_TO_CART', payload: item });
    };

    const removeFromCart = (id, size, coverType, coverName, type) => {
        dispatch({
            type: 'REMOVE_FROM_CART',
            payload: {
                id,
                size,
                coverSelection: {
                    coverType,
                    coverName,
                },
                type // Specify if it's a product or an album
            }
        });
    };

    const updateQuantity = (id, quantity) => {
        dispatch({ type: 'UPDATE_QUANTITY', payload: { id, quantity } });
    };

    return (
        <CartContext.Provider value={{
            cartItems: state.cartItems,
            addToCart,
            removeFromCart,
            updateQuantity,
        }}>
            {children}
        </CartContext.Provider>
    );
};

export { CartContext, CartProvider };
