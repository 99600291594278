import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-black-500.png';
import './Navbar.css';
import { FaShoppingCart } from 'react-icons/fa';
import { RxHamburgerMenu } from 'react-icons/rx';
import { RiCloseLine } from 'react-icons/ri';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { FiLogOut } from 'react-icons/fi';
import { UserContext } from '../../context/UserProvider';
import { CartContext } from '../../context/CartContext'
import NavbarProduct from './navbarProduct';
import axios from 'axios';



export default function Navbar() {
  const { user, logout } = useContext(UserContext);
  const {cartItems} = useContext(CartContext)


  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileProducts, setMobileProducts] = useState(null);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [albumData, setAlbumData] = useState([]);
  const [productData, setProductData] = useState([]);
  const dropdownRef = useRef(null);
  const navRef = useRef(null);
  const timeoutRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1001);

  const wallArt = productData.filter((product) => product.category === "Edge-Prints");
  const editing = productData.filter((product) => product.category === "Editing");
  const imageBox = productData.filter((product) => product.category === "Image-Box");

  useEffect(() => {
  
    const getData = async () => {
      try {
        
        const [albumsResponse, productResponse] = await Promise.all([
          axios.get(`${import.meta.env.VITE_API}/albums/all-albums`),
          axios.get(`${import.meta.env.VITE_API}/products/all-products`),
        ]);

        if (albumsResponse.data.length > 0) {
          setAlbumData(albumsResponse.data);
        }

        if (productResponse.data.length > 0) {
          setProductData(productResponse.data);
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      }
    };
    
    getData();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1001);
    
    window.addEventListener('resize', handleResize);
    
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = (link) => {
    clearTimeout(timeoutRef.current);
    setHoveredLink(link);
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      if (!dropdownRef.current.matches(':hover')) {
        setDropdownOpen(false);
        setHoveredLink(null);
      }
    }, 300);
  };

  const handleDropdownMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setDropdownOpen(true);
  };

  const handleDropdownMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setDropdownOpen(false);
      setHoveredLink(null);
    }, 300);
  };

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
    if (mobileMenu) {
      setMobileProducts(null);
    }
  };

  const toggleMobileProducts = (category) => {
    setMobileProducts((prev) => (prev === category ? null : category));
  };

  const getFirstName = (fullName) => {
    return fullName ? fullName.split(' ')[0] : '';
  };

  return (
    <nav className={`navbar ${dropdownOpen ? 'expand-navbar' : ''}`} ref={navRef}>
      <a href='/'>
        <img src={logo} width={125} alt='logo image' />
      </a>

      <div className='hamburger-menu'>
        {mobileMenu ? (
          <RiCloseLine size={25} onClick={toggleMobileMenu} />
        ) : (
          <RxHamburgerMenu size={25} onClick={toggleMobileMenu} />
        )}
      </div>

      <div className="nav-wrapper">
        <div 
          className={`nav-links ${mobileMenu ? 'mobileNavBar' : ''}`}
          onMouseLeave={handleMouseLeave}
        >
          {user && user.role === "Admin" &&
            <>
              <Link to={'/admin/outstanding-orders'}>Orders</Link>
              <Link to={'/admin/products'}>Products</Link>
              <Link to={'/admin/editors'}>Editing</Link>
              <Link to={'/admin/add-products'}>Add Products</Link>
            </>
          }
         
          <a 
            href="#" // Prevent navigation for mobile
            onMouseEnter={() => handleMouseEnter('albums')}
            onClick={(e) => {
              e.preventDefault(); // Prevent the default navigation behavior
              toggleMobileProducts('albums'); // Show the albums products
            }}
          >
            Albums
          </a>
          <a 
            href="#" 
            onMouseEnter={() => handleMouseEnter('editing')}
            onClick={(e) => {
              e.preventDefault();
              toggleMobileProducts('editing');
            }}
          >
            Editing
          </a>
          <a 
            href="#" 
            onMouseEnter={() => handleMouseEnter('wallArt')}
            onClick={(e) => {
              e.preventDefault();
              toggleMobileProducts('wallArt');
            }}
          >
            Wall Art
          </a>
          <a 
            href="#" 
            onMouseEnter={() => handleMouseEnter('imageBox')}
            onClick={(e) => {
              e.preventDefault();
              toggleMobileProducts('imageBox');
            }}
          >
            Image Box
          </a>
          <Link to='/about'>About</Link>
          {user && (user.firstName || user.fullName) && <Link to={`/${user._id}/orders`}>My Orders</Link> }
          
        </div>


        <div 
          className={`product-dropdown ${dropdownOpen ? 'open' : ''}`} 
          ref={dropdownRef}
          onMouseEnter={handleDropdownMouseEnter}
          onMouseLeave={handleDropdownMouseLeave}
        >
          {!isMobile && dropdownOpen && hoveredLink === 'albums' && albumData.length > 0 && albumData.map((item, index) => (
            <NavbarProduct
              name={item.albumName}
              image={item.navbarImage}
              learnLink={`/albums/${item.albumName}`}
              orderLink={`/shop/albums/${item.albumName}`}
              key={index}
            />
          ))}
          {!isMobile && dropdownOpen && hoveredLink === 'wallArt' && wallArt.map((item, index) => (
            <NavbarProduct 
              name={item.productName}
              image={item.navbarImage}
              key={index}
              learnLink={`/products/${item.productName}`}
              orderLink={`/shop/products/${item.productName}`}
            />
          ))}
          {!isMobile && dropdownOpen && hoveredLink === "editing" && editing.map((item, index) => (
            <NavbarProduct
              name={item.productName}
              image={item.navbarImage}
              caveat={"Album Purchase Required"}
              key={index}
            />
          ))}
          {!isMobile && dropdownOpen && hoveredLink === "imageBox" && <h2>Coming Soon!</h2>}
        </div>
      </div>

      <div className={`cart-profile ${mobileMenu ? 'mobile' : 'desktop'}`}>
        <Link to='/cart' className='cart-link'>
          <FaShoppingCart /> {cartItems.length}
        </Link>

        {user && (user.firstName || user.fullName) ?  
            <div className='user-info'>
              <span>Welcome, {user.firstName}!</span>
              <p onClick={logout} className='logout-button' style={{ color: '#3c3c3c', cursor: 'pointer', marginTop: '0px' }}>
                <FiLogOut size={20} /> Logout
              </p>
            </div>
            :
            <Link to='/login'>Login</Link>
        }
      </div>

     {/* Mobile Product dropdowns */}
        {isMobile && mobileMenu && mobileProducts === 'albums' && (
          
          <div className='mobile-product-dropdown'>
             <div className='back-button' onClick={() => setMobileProducts(null)}>
              <RiArrowLeftSLine size={24} /> Back
            </div>
            {albumData.map((item, index) => (
              <NavbarProduct
              name={item.albumName}
              image={item.navbarImage}
              learnLink={`/albums/${item.albumName}`}
              orderLink={`/shop/albums/${item.albumName}`}
              key={index}
            />
            ))}
          </div>
        )}
        {isMobile && mobileMenu && mobileProducts === 'wallArt' && (
          <div className='mobile-product-dropdown'>
            <div className='back-button' onClick={() => setMobileProducts(null)}>
              <RiArrowLeftSLine size={24} /> Back
            </div>
            {wallArt.map((item, index) => (
              <NavbarProduct 
                name={item.productName}
                image={item.navbarImage}
                key={index}
                learnLink={`/products/${item.productName}`}
                orderLink={`/shop/products/${item.productName}`}
              />
            ))}
          </div>
        )}
        {isMobile && mobileMenu && mobileProducts === 'editing' && (
          <div className='mobile-product-dropdown'>
            <div className='back-button' onClick={() => setMobileProducts(null)}>
              <RiArrowLeftSLine size={24} /> Back
            </div>
            {editing.map((item, index) => (
              <NavbarProduct
              name={item.productName}
              image={item.navbarImage}
              caveat={"Album Purchase Required"}
              key={index}
            />
            ))}
          </div>
        )}
        {isMobile && mobileMenu && mobileProducts === 'imageBox' && (
          <div className='mobile-product-dropdown'>
            <div className='back-button' onClick={() => setMobileProducts(null)}>
              <RiArrowLeftSLine size={24} /> Back
            </div>
            <h2>Coming Soon!</h2>
          </div>
        )}
    </nav>
  );
}